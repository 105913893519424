import styles from "@/styles/LivePhotoIcon.module.css";
import classNames from "classnames";
import { t } from "i18next";

function LivePhotoIcon(): JSX.Element {
	return (
		<div
			className={styles["live-photo-icon"]}
			title={t("livePhoto")}
		>
			<div
				className={classNames(styles["circle"], styles["outer-circle"])}
			></div>
			<div
				className={classNames(
					styles["circle"],
					styles["middle-circle"],
				)}
			></div>
			<div
				className={classNames(styles["circle"], styles["inner-circle"])}
			></div>
		</div>
	);
}

export default LivePhotoIcon;
