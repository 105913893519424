import styles from "@/styles/WifiTransferButtons.module.css";
import { MenuItemInfo } from "@/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface WifiTransferButtonsProps {
	buttons: readonly MenuItemInfo[];
}

function WifiTransferButtons({
	buttons,
}: WifiTransferButtonsProps): JSX.Element {
	const elements = buttons.map((button) => {
		const onClick = (): void => {
			void button.onClick();
		};

		return (
			<button
				aria-haspopup={button.hasPopup ? "dialog" : undefined}
				className={styles["button"]}
				key={button.label}
				onClick={onClick}
				type="button"
			>
				<FontAwesomeIcon icon={button.icon} />
				<span>{button.label}</span>
			</button>
		);
	});

	return <div className={styles["container"]}>{elements}</div>;
}

export default WifiTransferButtons;
