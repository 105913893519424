import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";

interface SlidesProps {
	children?: JSX.Element[];
	currentPage: number;
	isBackward: boolean;
}

function Slides({
	children,
	currentPage,
	isBackward,
}: SlidesProps): JSX.Element {
	if (!children || children.length === 0) {
		return <></>;
	}

	const elements = children.map((item, index) => {
		if (currentPage !== index) {
			return null;
		}
		return (
			<m.div
				className="universal-container"
				key={index}
				initial={{ opacity: 0, x: isBackward ? -100 : 100 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: isBackward ? 100 : -100 }}
				transition={{ duration: 0.15 }}
			>
				{item}
			</m.div>
		);
	});

	return (
		<LazyMotion
			features={domAnimation}
			strict
		>
			<AnimatePresence
				initial={false}
				mode="wait"
			>
				{elements}
			</AnimatePresence>
		</LazyMotion>
	);
}

export default Slides;
