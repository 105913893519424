import { useAppSelector } from "@/redux/hooks";
import styles from "@/styles/Greeting.module.css";
import { t } from "i18next";

function Greeting(): JSX.Element {
	const login = useAppSelector((state) => state.app.login);

	let hours = new Date().getHours();
	if (hours > 23) {
		hours = hours - 24;
	} else if (hours < 0) {
		hours = hours + 24;
	}
	let greeting;
	if (hours >= 5 && hours < 12) {
		greeting = t("greetingMorning");
	}
	if (hours >= 12 && hours < 18) {
		greeting = t("greetingAfternoon");
	}
	if (hours >= 18 || hours < 5) {
		greeting = t("greetingNight");
	}

	if (!login.username) {
		return <></>;
	}
	return (
		<div className={styles["container"]}>
			{greeting}
			{login.name || login.email || login.phone}
		</div>
	);
}

export default Greeting;
