import ActionButtonBar from "@/components/ActionButtonBar";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setQrCode, setSendPage } from "@/redux/reducers/app";
import { SendPage } from "@/types";
import { copyText } from "@/utils";
import { faLink, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { useEffect } from "react";

function SendSuccess(): JSX.Element {
	const dispatch = useAppDispatch();
	const sendSuccessInfo = useAppSelector(
		(state) => state.app.sendSuccessInfo,
	);

	const url =
		(globals.dynamicInfo.downloadLink || globals.FRONTEND) +
		sendSuccessInfo.code +
		"/" +
		sendSuccessInfo.key;

	const actionButtons = [
		{
			attributes: {
				"data-clipboard-text": url, // for cypress testing
				"onClick": (): void => {
					void copyText(
						dispatch,
						`${url} ${t("copyLinkIntoBrowserToOpen")}`,
					);
				},
			},
			icon: faLink,
			label: t("copyDownloadLink"),
		},
		{
			attributes: {
				onClick: (): void => {
					dispatch(setQrCode(url));
					dispatch(setSendPage(SendPage.QR_CODE));
				},
			},
			icon: faQrcode,
			label: t("showQrCode"),
		},
	];

	useEffect(() => {
		if (!sendSuccessInfo.code) {
			return;
		}
		document.title = `[${t("code")} ${sendSuccessInfo.code}] ${t("airportalTitle")}`;
		return (): void => {
			document.title = t("airportalTitle");
		};
	}, [sendSuccessInfo.code]);

	if (!sendSuccessInfo.code) {
		return <></>;
	}
	return (
		<div className="universal-container">
			<h1 className="popup-title">{t("fileSentSuccessfully")}</h1>
			<div className="secondary-text">{t("yourCode")}</div>
			<div className="code selectable">{sendSuccessInfo.code}</div>
			<div className="secondary-text">
				{t("enterAboveCodeWhenReceiving")}
			</div>
			<ActionButtonBar buttons={actionButtons} />
		</div>
	);
}

export default SendSuccess;
