import globals from "@/globals";
import { useAppDispatch } from "@/redux/hooks";
import { setSendPage } from "@/redux/reducers/app";
import styles from "@/styles/SendAgreement.module.css";
import { SendPage } from "@/types";
import { handleKeyboardClick, isMainland } from "@/utils";
import classNames from "classnames";
import { t } from "i18next";

interface SendAgreementProps {
	closeSendPopup: () => void;
}

function SendAgreement({ closeSendPopup }: SendAgreementProps): JSX.Element {
	const dispatch = useAppDispatch();

	const handleAgreeClick = (): void => {
		if (globals.login.username || !isMainland()) {
			void globals.sendCallback?.();
		} else {
			dispatch(setSendPage(SendPage.ID_SELECTOR));
		}
	};

	return (
		<>
			<h1 className="popup-title">{t("warning")}</h1>
			<div
				className={classNames(
					"content-left-aligned",
					styles["agreement-container"],
				)}
				dangerouslySetInnerHTML={{
					__html: t("illegalFilesWarning"),
				}}
			></div>
			<div className={styles["button-bar"]}>
				<button
					className="popup-main-button"
					type="button"
					onClick={handleAgreeClick}
				>
					{t("agreeAndContinue")}
				</button>
				<span
					className="link"
					role="button"
					tabIndex={0}
					onClick={closeSendPopup}
					onKeyDown={handleKeyboardClick(closeSendPopup)}
				>
					{t("cancelUpload")}
				</span>
			</div>
		</>
	);
}

export default SendAgreement;
