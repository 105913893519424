import Switch from "@/components/Switch";
import styles from "@/styles/SettingsItem.module.css";
import classNames from "classnames";
import { t } from "i18next";
import { Fragment, useId } from "react";

interface SettingsItemProps {
	button?: string;
	checked?: boolean;
	label: string;
	note?: string;
	onClick?: () => void | Promise<void>;
	options?: {
		label: string;
		value: string;
		when?: boolean;
	}[];
	setChecked?: (value: boolean) => void | Promise<void>;
	setValue?: (value: string) => void | Promise<void>;
	value?: string;
	when: boolean;
}

function SettingsItem({
	button,
	checked,
	label,
	note,
	onClick,
	options,
	setChecked,
	setValue,
	value,
	when,
}: SettingsItemProps): JSX.Element {
	const id = useId();

	if (!when) {
		return <Fragment key={id} />;
	}

	if (button) {
		const handleClick = (): void => {
			void onClick?.();
		};

		return (
			<div
				className={styles["settings-item"]}
				key={id}
			>
				<div className={styles["no-text-overflow"]}>
					<label htmlFor={id}>{label}</label>
					{note && (
						<div
							className={classNames(
								styles["note"],
								styles["no-text-overflow"],
							)}
						>
							{note}
						</div>
					)}
				</div>
				<button
					id={id}
					onClick={handleClick}
					type="button"
				>
					{button}
				</button>
			</div>
		);
	}

	if (options) {
		const optionElements = options.map((option) => {
			if (option.when === false) {
				return null;
			}
			return (
				<option
					key={option.value}
					value={option.value}
				>
					{option.label}
				</option>
			);
		});

		const handleSelectChange = (
			event: React.ChangeEvent<HTMLSelectElement>,
		): void => {
			const newValue = event.target.value;
			void setValue?.(newValue);
		};

		return (
			<div
				className={styles["settings-item"]}
				key={id}
			>
				<label htmlFor={id}>{label}</label>
				<select
					id={id}
					value={value}
					onChange={handleSelectChange}
				>
					{optionElements}
				</select>
			</div>
		);
	}

	const handleCheckboxClick = (): void => {
		const newValue = !checked;
		void setChecked?.(newValue);
	};

	return (
		<div
			key={id}
			className={styles["settings-item"]}
			aria-checked={checked}
			aria-label={label}
		>
			<div className={styles["checkbox-label"]}>{label}</div>
			<Switch
				checked={checked}
				onChange={handleCheckboxClick}
				title={t(label)}
			/>
		</div>
	);
}

export default SettingsItem;
