import * as api from "@/api";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import styles from "@/styles/MyFiles.module.css";
import { copyText, getFileIcon, showDialog } from "@/utils";
import {
	faDownload,
	faLink,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { t } from "i18next";
import { MouseEvent } from "react";

interface MyFilesProps {
	receiveFile: (code: string) => void;
}

function MyFiles({ receiveFile }: MyFilesProps): JSX.Element {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const login = useAppSelector((state) => state.app.login);

	const { data, error, isFetching, refetch } = useQuery({
		queryFn: api.getMyFiles,
		queryKey: ["myFiles", login.username],
		retry: false,
	});

	const handleRefreshClick = (): void => {
		void refetch();
	};

	const listElements = data?.map((item, index): JSX.Element => {
		const downloadLink =
			(globals.dynamicInfo.downloadLink || globals.FRONTEND) +
			item.code +
			"/" +
			item.key;
		const filename = decodeURIComponent(item.name);
		const isExpired = item.downloads === 0;

		const copyDownloadLink = (): void => {
			void copyText(
				dispatch,
				`${downloadLink} ${t("copyLinkIntoBrowserToOpen")}`,
			);
		};

		const deleteFile = async (): Promise<void> => {
			await showDialog(
				dispatch,
				t("confirmDeleteFromServer", {
					name: filename,
				}),
				{
					showCancel: true,
				},
			);
			dispatch(setIsLoadingScreenShown(true));
			try {
				await api.deleteFile(item.code.toString());
				queryClient.setQueryData(
					["myFiles", login.username],
					data.filter((_item) => {
						return _item.code !== item.code;
					}),
				);
			} catch (error) {
				api.handleApiError(dispatch, error);
			} finally {
				dispatch(setIsLoadingScreenShown(false));
			}
		};

		const downloadFile = (event: MouseEvent<HTMLElement>): void => {
			event.preventDefault();
			receiveFile(item.code.toString());
		};

		const handleDeleteClick = (): void => {
			void deleteFile();
		};

		return (
			<div
				className={classNames("list-item", styles["list-item"])}
				key={index}
			>
				<div className={styles["info-container"]}>
					<FontAwesomeIcon
						className="file-icon"
						fixedWidth
						icon={getFileIcon(item.type)}
					/>
					<div>
						<div className={styles["list-item-title"]}>
							<a
								aria-disabled={isExpired}
								href={downloadLink}
								onClick={downloadFile}
								target="_blank"
								title={[
									t("download"),
									filename,
									t("code"),
									item.code,
								].join(" ")}
								rel="noreferrer"
							>
								{item.code}
							</a>
							<span
								className={classNames(
									styles["list-item-subtitle"],
									{ [styles["expired"]]: isExpired },
								)}
							>
								{t("downloadsLeft", {
									downloads: item.hasUnlimitedDownloads
										? "∞"
										: item.downloads,
								})}
							</span>
						</div>
						<p>{filename}</p>
					</div>
				</div>
				<div className={styles["actions-container"]}>
					<button
						className={styles["action-button"]}
						disabled={isExpired}
						onClick={downloadFile}
						title={t("download")}
						type="button"
					>
						<FontAwesomeIcon icon={faDownload} />
					</button>
					<button
						className={styles["action-button"]}
						disabled={isExpired}
						onClick={copyDownloadLink}
						title={t("copyDownloadLink")}
						type="button"
					>
						<FontAwesomeIcon icon={faLink} />
					</button>
					<button
						className={classNames(
							styles["action-button"],
							styles["delete-button"],
						)}
						onClick={handleDeleteClick}
						title={t("delete")}
						type="button"
					>
						<FontAwesomeIcon icon={faTrashCan} />
					</button>
				</div>
			</div>
		);
	});

	return (
		<>
			<h1 className="popup-title">{t("myFiles")}</h1>
			<div className={styles["list"]}>
				{isFetching && (
					<div className={styles["placeholder"]}>{t("loading")}</div>
				)}
				{!isFetching && error && (
					<div className={styles["placeholder"]}>{error.message}</div>
				)}
				{!isFetching && !error && (!data || data.length === 0) && (
					<div className={styles["placeholder"]}>
						{t("noAnyFiles")}
					</div>
				)}
				{!isFetching && data && data.length > 0 && listElements}
			</div>
			<button
				className="popup-main-button"
				type="button"
				disabled={isFetching}
				onClick={handleRefreshClick}
			>
				{t("refresh")}
			</button>
		</>
	);
}

export default MyFiles;
