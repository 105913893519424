import {
	DialogInfo,
	FileInfo,
	LinkInfo,
	LoginInfo,
	NotificationInfo,
	PosterInfo,
	ProgressInfo,
	ReceivePage,
	SendPage,
	SendSuccessInfo,
	ServerInfo,
	SettingsInfo,
	ToastInfo,
	WifiTransferPage,
} from "@/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	arePopupsShown: {
		about: false,
		article: false,
		receive: false,
		send: false,
		senderCode: false,
		settings: false,
		wifiTransfer: false,
	},
	article: {} as PosterInfo,
	code: "",
	currentFile: {} as FileInfo,
	dialog: {} as DialogInfo,
	invalidAttempts: {
		code: 0,
		senderCode: 0,
	},
	isCaptchaShown: false,
	isLoadingScreenShown: false,
	isMainBoxShown: true,
	isMenuShown: false,
	isMyFilesButtonHighlighted: false,
	links: [] as LinkInfo[],
	login: {} as LoginInfo,
	notification: {} as NotificationInfo,
	password: "",
	pendingUploadFiles: [] as FileInfo[],
	posters: [] as PosterInfo[],
	premiumData: 0,
	progress: {} as ProgressInfo,
	qrCode: "",
	receivedFiles: [] as FileInfo[],
	receivePage: ReceivePage.CODE,
	selectedServer: "",
	senderCode: "",
	sendPage: SendPage.FILE_LIST,
	sendSuccessInfo: {} as SendSuccessInfo,
	servers: {} as ServerInfo,
	settings: {} as SettingsInfo,
	toast: {} as ToastInfo,
	wallpaper: null as string | null,
	wifiTransferPage: WifiTransferPage.MODE,
	wifiTransferServerIp: "",
};

const slice = createSlice({
	initialState: initialState,
	name: "app",
	reducers: {
		closePopup: (state, action) => {
			const key = action.payload as keyof typeof state.arePopupsShown;
			state.arePopupsShown[key] = false;
			for (const value of Object.values(state.arePopupsShown)) {
				if (value) {
					return;
				}
			}
			state.isMainBoxShown = true;
		},
		increaseInvalidCodeAttempts: (state, action) => {
			const newValue = action.payload as number;
			state.invalidAttempts.code += newValue;
		},
		increaseInvalidSenderCodeAttempts: (state, action) => {
			const newValue = action.payload as number;
			state.invalidAttempts.senderCode += newValue;
		},
		mergeSettings: (state, action) => {
			const newValue = action.payload as SettingsInfo;
			state.settings = { ...state.settings, ...newValue };
		},
		openPopup: (state, action) => {
			state.isMainBoxShown = false;
			const key = action.payload as keyof typeof state.arePopupsShown;
			state.arePopupsShown[key] = true;
		},
		setArticle: (state, action) => {
			const newValue = action.payload as PosterInfo;
			state.article = newValue;
			state.isMainBoxShown = false;
			state.arePopupsShown.article = true;
		},
		setCode: (state, action) => {
			const newValue = action.payload as string;
			state.code = newValue;
		},
		setCurrentFile: (state, action) => {
			const newValue = action.payload as FileInfo;
			state.currentFile = newValue;
		},
		setDialog: (state, action) => {
			const newValue = action.payload as DialogInfo;
			state.dialog = newValue;
		},
		setIsCaptchaShown: (state, action) => {
			const newValue = action.payload as boolean;
			state.isCaptchaShown = newValue;
		},
		setIsLoadingScreenShown: (state, action) => {
			const newValue = action.payload as boolean;
			state.isLoadingScreenShown = newValue;
		},
		setIsMenuShown: (state, action) => {
			const newValue = action.payload as boolean;
			state.isMenuShown = newValue;
		},
		setIsMyFilesButtonHighlighted: (state, action) => {
			const newValue = action.payload as boolean;
			state.isMyFilesButtonHighlighted = newValue;
		},
		setLinks: (state, action) => {
			const newValue = action.payload as LinkInfo[];
			state.links = newValue;
		},
		setLogin: (state, action) => {
			const newValue = action.payload as LoginInfo;
			state.login = { ...newValue };
		},
		setNotification: (state, action) => {
			const newValue = action.payload as DialogInfo;
			state.notification = newValue;
		},
		setPassword: (state, action) => {
			const newValue = action.payload as string;
			state.password = newValue;
		},
		setPendingUploadFiles: (state, action) => {
			const newValue = action.payload as FileInfo[];
			state.pendingUploadFiles = newValue;
		},
		setPosters: (state, action) => {
			const newValue = action.payload as PosterInfo[];
			state.posters = newValue;
		},
		setPremiumData: (state, action) => {
			const newValue = action.payload as number;
			state.premiumData = newValue;
		},
		setProgress: (state, action) => {
			const newValue = action.payload as ProgressInfo;
			state.progress = newValue;
		},
		setProgressPercent: (state, action) => {
			const newValue = action.payload as number;
			state.progress.percent = newValue;
		},
		setQrCode: (state, action) => {
			const newValue = action.payload as string;
			state.qrCode = newValue;
		},
		setReceivedFiles: (state, action) => {
			const newValue = action.payload as FileInfo[];
			state.receivedFiles = newValue;
		},
		setReceivePage: (state, action) => {
			const newValue = action.payload as ReceivePage;
			state.receivePage = newValue;
		},
		setSelectedServer: (state, action) => {
			const newValue = action.payload as string;
			state.selectedServer = newValue;
		},
		setSenderCode: (state, action) => {
			const newValue = action.payload as string;
			state.senderCode = newValue;
		},
		setSendPage: (state, action) => {
			const newValue = action.payload as SendPage;
			state.sendPage = newValue;
		},
		setSendSuccessInfo: (state, action) => {
			const newValue = action.payload as SendSuccessInfo;
			state.sendSuccessInfo = newValue;
		},
		setServers: (state, action) => {
			const newValue = action.payload as ServerInfo;
			state.servers = newValue;
		},
		setToast: (state, action) => {
			const newValue = action.payload as ToastInfo;
			state.toast = newValue;
		},
		setWallpaper: (state, action) => {
			const newValue = action.payload as string | null;
			state.wallpaper = newValue;
		},
		setWifiTransferPage: (state, action) => {
			const newValue = action.payload as WifiTransferPage;
			state.wifiTransferPage = newValue;
		},
		setWifiTransferServerIp: (state, action) => {
			const newValue = action.payload as string;
			state.wifiTransferServerIp = newValue;
		},
	},
});

export const {
	closePopup,
	increaseInvalidCodeAttempts,
	increaseInvalidSenderCodeAttempts,
	mergeSettings,
	openPopup,
	setArticle,
	setCode,
	setCurrentFile,
	setDialog,
	setIsCaptchaShown,
	setIsLoadingScreenShown,
	setIsMenuShown,
	setIsMyFilesButtonHighlighted,
	setLinks,
	setLogin,
	setNotification,
	setPassword,
	setPendingUploadFiles,
	setPosters,
	setPremiumData,
	setProgress,
	setProgressPercent,
	setQrCode,
	setReceivedFiles,
	setReceivePage,
	setSelectedServer,
	setSenderCode,
	setSendPage,
	setSendSuccessInfo,
	setServers,
	setToast,
	setWallpaper,
	setWifiTransferPage,
	setWifiTransferServerIp,
} = slice.actions;
export default slice.reducer;
