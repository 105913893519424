import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setSendPage } from "@/redux/reducers/app";
import styles from "@/styles/SendIdProvider.module.css";
import { SendPage } from "@/types";
import { handleKeyboardClick, logIn, showDialog } from "@/utils";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { t } from "i18next";
import { useEffect } from "react";

function SendIdProvider(): JSX.Element {
	const dispatch = useAppDispatch();
	const invalidAttempts = useAppSelector(
		(state) => state.app.invalidAttempts,
	);
	const login = useAppSelector((state) => state.app.login);

	const selectPersonalDevice = (): void => {
		if (login.username) {
			void globals.sendCallback?.();
		} else {
			logIn();
		}
	};

	const selectPublicDevice = (): void => {
		dispatch(setSendPage(SendPage.SENDER_CODE));
	};

	const whyLogin = (): void => {
		void showDialog(
			dispatch,
			"1. 根据《中华人民共和国网络安全法》等法律法规和监管部门要求，发送内容必须实名登录；" +
				"2. 为配合执法部门打击违法违规内容犯罪，我们必须记录上传者实名信息；" +
				"3. 登录后，您可享受云端同步、多端协同等便捷功能；" +
				"4. 如果您正在使用公共设备，不想在该设备输入账号和密码，可以选择第二个选项。感谢您的理解！",
			{
				title: t("whyLogin"),
			},
		);
	};

	useEffect(() => {
		if (login.username) {
			void globals.sendCallback?.();
		}
	}, [login.username]);

	return (
		<>
			<h1 className="popup-title">{t("chooseIdentityProvider")}</h1>
			<div className={styles["id-provider-list"]}>
				<button
					className={classNames(
						styles["option"],
						styles["personal-device"],
					)}
					type="button"
					onClick={selectPersonalDevice}
				>
					<div className={styles["icon-container"]}>
						<FontAwesomeIcon
							icon={faUser}
							size="xl"
						/>
					</div>
					<div className={styles["text-container"]}>
						<div className={styles["title"]}>
							{t("thisIsPersonalDeviceWannaLogin")}
							<span className={styles["description"]}>
								{t("recommended")}
							</span>
						</div>
						<div className={styles["description"]}>
							{t("personalDeviceDescription")}
						</div>
					</div>
				</button>
				<button
					className={classNames(
						styles["option"],
						styles["public-device"],
					)}
					type="button"
					disabled={invalidAttempts.senderCode > 2}
					onClick={selectPublicDevice}
				>
					<div className={styles["icon-container"]}>
						<FontAwesomeIcon
							icon={faUsers}
							size="xl"
						/>
					</div>
					<div className={styles["text-container"]}>
						<div className={styles["title"]}>
							{t("thisIsPublicDeviceDontWannaLogin")}
						</div>
						<div className={styles["description"]}>
							{t("publicDeviceDescription")}
						</div>
					</div>
				</button>
			</div>
			<span
				className={classNames("link", styles["link"])}
				role="button"
				tabIndex={0}
				onClick={whyLogin}
				onKeyDown={handleKeyboardClick(whyLogin)}
			>
				<FontAwesomeIcon
					className={styles["icon"]}
					icon={faCircleQuestion}
				/>
				{t("whyLogin")}
			</span>
		</>
	);
}

export default SendIdProvider;
