import * as api from "@/api";
import Reader from "@/classes/Reader";
import { importAliOss } from "@/dependencies";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	increaseInvalidSenderCodeAttempts,
	setIsLoadingScreenShown,
	setProgress,
	setProgressPercent,
	setSendPage,
	setSendSuccessInfo,
} from "@/redux/reducers/app";
import styles from "@/styles/SendFileList.module.css";
import { OssCredentials, SendPage } from "@/types";
import {
	getFileIcon,
	isMainland,
	logIn,
	openAccount,
	showCaptcha,
	showDialog,
	showResponseDialog,
	sleep,
} from "@/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkpoint } from "ali-oss";
import { t } from "i18next";
import {
	ChangeEvent,
	Dispatch,
	FormEvent,
	SetStateAction,
	useId,
	useState,
} from "react";

interface SendFileListProps {
	backToPage?: (page: SendPage) => void;
}

function SendFileList({ backToPage }: SendFileListProps): JSX.Element {
	const PART_SIZE = 5_242_880; // 5 MB
	const RETRY_COUNT_LIMIT = 5;
	const STS_TOKEN_TIMEOUT = 600_000; // 10 minutes

	const dispatch = useAppDispatch();

	const login = useAppSelector((state) => state.app.login);
	const pendingUploadFiles = useAppSelector(
		(state) => state.app.pendingUploadFiles,
	);
	const premiumData = useAppSelector((state) => state.app.premiumData);
	const senderCode = useAppSelector((state) => state.app.senderCode);

	const setDownloadsId = useId();
	const setHoursId = useId();
	const setPasswordId = useId();

	const defaultDownloads = Math.min(10, pendingUploadFiles.length + 1);

	const [downloads, setDownloads] = useState<string>(
		defaultDownloads.toString(),
	);
	const [hours, setHours] = useState<string>("24");
	const [password, setPassword] = useState<string>("");

	const sender =
		login.email ||
		login.phone ||
		(senderCode ? `${t("senderCode")} ${senderCode}` : t("notLoggedIn"));

	const fileNameElements = pendingUploadFiles.map((file, index) => {
		return (
			<li
				className={styles["list-item"]}
				key={index}
			>
				<FontAwesomeIcon
					className="file-icon"
					fixedWidth
					icon={getFileIcon(file.type)}
				/>
				{file.name}
			</li>
		);
	});

	const handleChange = (setter: Dispatch<SetStateAction<string>>) => {
		return (event: ChangeEvent<HTMLInputElement>): void => {
			setter(event.target.value);
		};
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		void upload();
	};

	const upload = async (): Promise<void> => {
		let downloadsNumber = parseInt(downloads);
		if (!downloadsNumber || downloadsNumber < 1) {
			setDownloads(defaultDownloads.toString());
			downloadsNumber = defaultDownloads;
		}
		const action = "send" + (senderCode ? "WithSenderCode" : "");
		if (!globals.login.username && isMainland() && !senderCode) {
			await showDialog(dispatch, t("requireLoginToSendFiles"));
			logIn();
			return;
		}
		dispatch(setIsLoadingScreenShown(true));
		let content: string | undefined = "";
		let hash = "";
		let type = "";
		try {
			const reader = new Reader(globals.pendingUploadFiles);
			const result = await reader.readFile();
			content = result.content;
			hash = result.hash;
			type = result.type;
		} catch (error) {
			if (process.env.NODE_ENV === "development") {
				console.warn(error);
			}
		}
		const captchaResponse = await showCaptcha(dispatch, action);
		try {
			const data = await api.sendFiles({
				captchaResponse: captchaResponse,
				content: content,
				downloads: downloadsNumber,
				files: pendingUploadFiles,
				hash: hash,
				hours: parseInt(hours),
				password: password,
				senderCode: senderCode,
				type: type,
			});
			dispatch(setIsLoadingScreenShown(false));
			if (data.error) {
				switch (data.error) {
					case "captchaRequired": {
						if (!globals.captchaRequiredFor.has(action)) {
							globals.captchaRequiredFor.add(action);
							await upload();
							return;
						}
						break;
					}
					case "invalidSenderCode": {
						dispatch(increaseInvalidSenderCodeAttempts(1));
						data.alert = t("senderCodeInvalidOrExpired");
						break;
					}
					case "premiumRequired": {
						await showDialog(dispatch, data.alert || data.error, {
							showCancel: true,
						});
						if (login.username) {
							openAccount("premium");
						} else {
							logIn();
						}
						return;
					}
					default:
						break;
				}
				await showResponseDialog(
					dispatch,
					data.alert || data.error,
					data.link,
				);
				backToPage?.(SendPage.ID_SELECTOR);
				return;
			}
			if (!data.code) {
				throw new Error("Missing code");
			}
			if (!data.id || !data.secret) {
				throw new Error("Missing credentials");
			}
			const bucket = data.server;
			if (!bucket) {
				throw new Error("Missing bucket");
			}
			desktopApp.startSleepBlocker();
			const OSS = await importAliOss();
			const ossClient = new OSS({
				accessKeyId: data.id,
				accessKeySecret: data.secret,
				bucket: data.server,
				cname: data.cname,
				endpoint: "https://" + data.endpoint,
				refreshSTSToken: async (): Promise<OssCredentials> => {
					return await api.renewStsToken(data.code?.toString());
				},
				refreshSTSTokenInterval: 900000,
				region: data.region,
				stsToken: data.token,
			});
			let currentCheckpoint: Checkpoint | undefined = undefined;
			let retryCount = 0;
			for (const file of globals.pendingUploadFiles) {
				if (file.size <= 0) {
					continue;
				}
				const ossKey = [data.code, data.key, 1, file.name].join("/");
				const percent = 0;
				const partCount = Math.ceil(file.size / PART_SIZE);
				dispatch(
					setProgress({
						denominator: partCount,
						filename: file.name,
						isReturnPremiumNoticeShown:
							premiumData > 0 &&
							file.size > globals.PREMIUM_REQUIRED_FILE_SIZE,
						percent: percent,
					}),
				);
				dispatch(setSendPage(SendPage.PROGRESS));

				const uploadToOss = async (): Promise<void> => {
					try {
						await ossClient.multipartUpload(ossKey, file, {
							checkpoint: currentCheckpoint,
							partSize: PART_SIZE,
							progress: (
								percent: number,
								checkpoint: Checkpoint,
							): void => {
								currentCheckpoint = checkpoint;
								dispatch(setProgressPercent(percent));
							},
							timeout: STS_TOKEN_TIMEOUT,
						});
					} catch (error) {
						console.error(error);
						if (retryCount < RETRY_COUNT_LIMIT) {
							retryCount++;
							await sleep(3000);
							await uploadToOss();
							return;
						}
						await showDialog(
							dispatch,
							<>
								{t("confirmRetryUpload")}
								{error instanceof Error && (
									<div className={styles["detailed-message"]}>
										{error.message}
									</div>
								)}
							</>,
							{
								showCancel: true,
							},
							{
								cancel: () => {
									dispatch(closePopup("send"));
								},
							},
						);
						retryCount = 0;
						await uploadToOss();
						return;
					}
				};

				await uploadToOss();
			}
			await api.sendSuccess(data.code.toString());
			dispatch(
				setSendSuccessInfo({
					code: data.code,
					key: data.key,
				}),
			);
			dispatch(setSendPage(SendPage.SUCCESS));
			globals.filesSentCount++;
			desktopApp.stopSleepBlocker();
		} catch (error) {
			dispatch(setIsLoadingScreenShown(false));
			api.handleApiError(dispatch, error);
		}
	};

	return (
		<div className="universal-container">
			<div className="popup-title no-margin">
				{t("youSelectedTheseFiles")}
			</div>
			<p className={styles["login-status"]}>{sender}</p>
			<ul className="file-list">{fileNameElements}</ul>
			<form onSubmit={handleSubmit}>
				<div className={styles["input-bar"]}>
					<label htmlFor={setPasswordId}>{t("password")}</label>
					<input
						autoComplete="off"
						data-testid="set-password-input"
						id={setPasswordId}
						placeholder={t("optional")}
						type="text"
						value={password}
						onChange={handleChange(setPassword)}
					/>
				</div>
				<div className={styles["input-bar"]}>
					<label htmlFor={setDownloadsId}>{t("downloads")}</label>
					<input
						autoComplete="off"
						id={setDownloadsId}
						type="number"
						value={downloads}
						onChange={handleChange(setDownloads)}
					/>
					<label htmlFor={setHoursId}>{t("hoursToBeSaved")}</label>
					<input
						autoComplete="off"
						id={setHoursId}
						type="number"
						value={hours}
						onChange={handleChange(setHours)}
					/>
				</div>
				<button
					className="popup-main-button"
					type="submit"
				>
					{t("upload")}
				</button>
			</form>
		</div>
	);
}

export default SendFileList;
