import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setToast } from "@/redux/reducers/app";
import styles from "@/styles/Toast.module.css";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";

function Toast(): JSX.Element {
	const dispatch = useAppDispatch();
	const toast = useAppSelector((state) => state.app.toast);

	const [isClosing, setIsClosing] = useState(false);

	const closeToast = useCallback((): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(setToast({}));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	}, [dispatch]);

	useEffect(() => {
		let timeoutId: number | undefined = undefined;
		if (toast.text) {
			timeoutId = window.setTimeout(() => {
				closeToast();
			}, toast.timeout || 3000);
		}
		return (): void => {
			window.clearTimeout(timeoutId);
		};
	}, [closeToast, toast]);

	if (!toast.text) {
		return <></>;
	}
	return (
		<div
			className={classNames({
				[styles["toast"]]: true,
				[styles["closing"]]: isClosing,
			})}
		>
			{toast.text}
		</div>
	);
}

export default Toast;
