import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setIsMenuShown } from "@/redux/reducers/app";
import styles from "@/styles/Wallpaper.module.css";
import { MouseEvent } from "react";

function Wallpaper(): JSX.Element {
	const dispatch = useAppDispatch();

	const premiumData = useAppSelector((state) => state.app.premiumData);
	const wallpaper = useAppSelector((state) => state.app.wallpaper);

	const handleContextMenu = (event: MouseEvent<HTMLImageElement>): void => {
		event.preventDefault();
		dispatch(setIsMenuShown(true));
	};

	if (premiumData <= 0 || !wallpaper) {
		return <></>;
	}
	return (
		<img
			alt=""
			className={styles["wallpaper"]}
			onContextMenu={handleContextMenu}
			src={wallpaper}
		/>
	);
}

export default Wallpaper;
