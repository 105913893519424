import App from "@/components/App";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import store from "@/redux/store";
import "@/styles/globals.css";
import translationEnUs from "@/translations/en-us.json";
import translationZhCn from "@/translations/zh-cn.json";
import translationZhTw from "@/translations/zh-tw.json";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18next, { t } from "i18next";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

const queryClient = new QueryClient();

if (window.location.hostname !== "localhost") {
	Sentry.init({
		dsn: "https://a856d1895c2440910b56eea2aa31c800@o4508388789387264.ingest.us.sentry.io/4508390157254656",
	});
}

const i18nResources = {
	"en-US": {
		translation: translationEnUs,
	},
	"zh-CN": {
		translation: translationZhCn,
	},
	"zh-TW": {
		translation: translationZhTw,
	},
};

const lang = ((): string => {
	let language: string | null = null;
	try {
		language = localStorage.getItem("Language");
	} catch (error) {
		console.error(error);
	}
	if (language && i18nResources[language as keyof typeof i18nResources]) {
		return language;
	} else if (
		!navigator.language ||
		/^(yue|zh)(-cn|-sg|-hans(-[a-z]+)?)?$/i.test(navigator.language) ||
		(/bot|spider/i.test(navigator.userAgent) &&
			!navigator.userAgent.includes("Googlebot"))
	) {
		return "zh-CN";
	} else if (
		navigator.language.startsWith("zh") ||
		navigator.language.startsWith("yue")
	) {
		return "zh-TW";
	} else {
		return "en-US";
	}
})();

i18next
	.init({
		fallbackLng: "en-US",
		interpolation: {
			escapeValue: false,
		},
		lng: lang,
		resources: i18nResources,
	})
	.then(() => {
		document.title = globals.isApp ? globals.APP_NAME : t("airportalTitle");
		if (!navigator.cookieEnabled) {
			window.alert(t("cookieDisabled"));
		}
		if (!lang.startsWith("zh")) {
			globals.home = "https://www.retinbox.com/";
		}
	})
	.catch(console.error);

document.documentElement.lang = lang;
desktopApp.init();

if (process.env.NODE_ENV === "production") {
	disableReactDevTools();
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<StrictMode>
		<ReduxProvider store={store}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</ReduxProvider>
	</StrictMode>,
);
