import styles from "@/styles/PopupShell.module.css";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton, Dialog } from "@headlessui/react";
import classNames from "classnames";
import { t } from "i18next";
import { ReactNode } from "react";

interface PopupShellProps {
	children: ReactNode;
	countdown?: ReactNode;
	isClosing: boolean;
	isOpen: boolean;
	onRequestClose?: () => void;
}

function PopupShell({
	children,
	countdown,
	isClosing,
	isOpen,
	onRequestClose,
}: PopupShellProps): JSX.Element {
	return (
		<Dialog
			onClose={onRequestClose || ((): void => {})}
			open={isOpen}
		>
			<div
				className={classNames({
					[styles["popup-overlay"]]: true,
					"zoom-in": !isClosing,
					"zoom-out": isClosing,
				})}
			>
				<div className={styles["popup"]}>
					<div className={styles["popup-content"]}>{children}</div>
					{countdown}
					{!!onRequestClose && (
						<CloseButton
							className={styles["close-button"]}
							title={t("close")}
							onClick={onRequestClose}
						>
							<FontAwesomeIcon icon={faClose} />
						</CloseButton>
					)}
				</div>
			</div>
		</Dialog>
	);
}

export default PopupShell;
