import Downloader from "@/classes/Downloader";
import LivePhotoIcon from "@/components/LivePhotoIcon";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setReceivePage } from "@/redux/reducers/app";
import styles from "@/styles/ReceiveFileList.module.css";
import { ReceivePage } from "@/types";
import { getFileIcon, logIn } from "@/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { t } from "i18next";
import { MouseEvent, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";

function ReceiveFileList(): JSX.Element {
	const dispatch = useAppDispatch();
	const premiumData = useAppSelector((state) => state.app.premiumData);
	const receivedFiles = useAppSelector((state) => state.app.receivedFiles);

	const filenamesSet = new Set<string>();
	for (const file of receivedFiles) {
		if (!file.name) {
			continue;
		}
		filenamesSet.add(file.name.toLowerCase());
	}

	const dlWarnFile = t("dlWarnFile", {
		extra:
			globals.dynamicInfo.executable &&
			receivedFiles[0]?.name &&
			new RegExp(globals.dynamicInfo.executable).test(
				receivedFiles[0].name,
			)
				? renderToStaticMarkup(
						<span className="emphasize">
							{t("executableFileReceived")}
						</span>,
					)
				: "",
		region: receivedFiles[0]?.region
			? renderToStaticMarkup(
					<>
						<span className="box">
							IP 属地{receivedFiles[0].region}
						</span>
						的
					</>,
				)
			: "",
	});

	const downloadAll = (): void => {
		void new Downloader(
			dispatch,
			receivedFiles,
			premiumData > 0,
		).downloadFiles();
	};

	const getLivePhotoVideoByImage = (filename?: string): string | null => {
		if (!filename) {
			return null;
		}
		const filenameLowered = filename.toLowerCase();
		if (!filenameLowered.endsWith(".pvt.jpeg")) {
			return null;
		}
		const videoFilename = filenameLowered.replace(".pvt.jpeg", ".pvt.mov");
		if (!filenamesSet.has(videoFilename)) {
			return null;
		}
		return videoFilename;
	};

	const isLivePhotoImage = (filename?: string): boolean => {
		if (!globals.isApp || !globals.isWindows) {
			return false;
		}
		return !!getLivePhotoVideoByImage(filename);
	};

	const isLivePhotoVideo = (filename?: string): boolean => {
		if (!filename || !globals.isApp || !globals.isWindows) {
			return false;
		}
		const filenameLowered = filename.toLowerCase();
		return (
			filenameLowered.endsWith(".pvt.mov") &&
			filenamesSet.has(filenameLowered.replace(".pvt.mov", ".pvt.jpeg"))
		);
	};

	const fileElements = receivedFiles.map((file, index) => {
		if (isLivePhotoVideo(file.name)) {
			return null;
		}

		const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
			if (!desktopApp.isElectron) {
				return;
			}
			event.preventDefault();
			const filesInfo = [file];
			if (globals.isWindows) {
				const livePhotoVideoFilename = getLivePhotoVideoByImage(
					file.name,
				);
				const livePhotoVideo = receivedFiles.find(
					(item) =>
						item.name?.toLowerCase() === livePhotoVideoFilename,
				);
				if (livePhotoVideo) {
					filesInfo.push(livePhotoVideo);
				}
			}
			void new Downloader(
				dispatch,
				filesInfo,
				premiumData > 0,
			).downloadFiles();
		};

		return (
			<a
				className="interactive"
				href={file.download?.[0] || "#"}
				key={index}
				target="_blank"
				rel="noreferrer"
				onClick={handleClick}
			>
				<div className={styles["file-name-container"]}>
					<FontAwesomeIcon
						className="file-icon"
						fixedWidth
						icon={getFileIcon(file.type)}
					/>
					{file.name && decodeURIComponent(file.name)}
				</div>
				{isLivePhotoImage(file.name) && <LivePhotoIcon />}
			</a>
		);
	});

	useEffect(() => {
		const reportLink = document.getElementById("report");
		if (reportLink) {
			reportLink.onclick = (event): void => {
				event.preventDefault();
				if (!globals.login.username) {
					logIn();
					return;
				}
				dispatch(setReceivePage(ReceivePage.REPORT));
			};
		}
	}, [dispatch, dlWarnFile]);

	return (
		<div className={classNames("universal-container", styles["container"])}>
			<div className={classNames("popup-title", styles["popup-title"])}>
				{t("filesReceived")}
			</div>
			<p className={styles["description"]}>
				{t("clickToDownloadSeparately")}
			</p>
			<div className={classNames("file-list", styles["file-list"])}>
				<div
					className="warning"
					dangerouslySetInnerHTML={{ __html: dlWarnFile }}
				></div>
				{fileElements}
			</div>
			<button
				className="popup-main-button"
				type="button"
				onClick={downloadAll}
			>
				{t("downloadAll")}
			</button>
		</div>
	);
}

export default ReceiveFileList;
