import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	increaseInvalidSenderCodeAttempts,
	setSenderCode,
} from "@/redux/reducers/app";
import styles from "@/styles/ReceiveCodeInput.module.css";
import { SendPage } from "@/types";
import { showDialog } from "@/utils";
import classNames from "classnames";
import { t } from "i18next";
import { ChangeEvent, FormEvent, useId } from "react";

interface SenderCodeInputProps {
	backToPage: (page: SendPage) => void;
}

function SenderCodeInput({ backToPage }: SenderCodeInputProps): JSX.Element {
	const dispatch = useAppDispatch();

	const senderCode = useAppSelector((state) => state.app.senderCode);

	const id = useId();

	const handleInvalidCode = async (): Promise<void> => {
		dispatch(increaseInvalidSenderCodeAttempts(1));
		dispatch(setSenderCode(""));
		await showDialog(dispatch, t("senderCodeInvalidOrExpired"));
		backToPage(SendPage.ID_SELECTOR);
	};

	const handleSenderCodeChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		dispatch(setSenderCode(event.target.value));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		const senderCodeNumber = Number(senderCode);
		if (
			isNaN(senderCodeNumber) ||
			senderCodeNumber < 100000 ||
			senderCodeNumber > 999999
		) {
			void handleInvalidCode();
			return;
		}
		void globals.sendCallback?.(senderCode);
	};

	return (
		<form
			className="universal-container"
			onSubmit={handleSubmit}
		>
			<div className="popup-title">
				<label htmlFor={id}>{t("enterSenderCode")}</label>
				<div className="tip">
					{t("getSenderCodeOnYourOtherLoggedInDevice")}
				</div>
			</div>
			<div>
				<input
					className={classNames({
						input: true,
						[styles["receive-input"]]: true,
					})}
					data-autofocus
					id={id}
					type="tel"
					autoComplete="off"
					inputMode="numeric"
					value={senderCode}
					onChange={handleSenderCodeChange}
				/>
			</div>
			<button
				className="popup-main-button"
				type="submit"
			>
				{t("ok")}
			</button>
		</form>
	);
}

export default SenderCodeInput;
