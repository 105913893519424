import * as api from "@/api";
import Downloader from "@/classes/Downloader";
import globals from "@/globals";
import {
	increaseInvalidCodeAttempts,
	setCode,
	setCurrentFile,
	setIsLoadingScreenShown,
	setPassword,
	setReceivePage,
	setReceivedFiles,
} from "@/redux/reducers/app";
import { ReceivePage, ReduxDispatch } from "@/types";
import {
	logIn,
	requireApp,
	showCaptcha,
	showDialog,
	showResponseDialog,
} from "@/utils";
import { t } from "i18next";
import { RefObject } from "react";

class Receiver {
	private readonly dispatch: ReduxDispatch;
	private readonly hasPremium: boolean;
	private readonly codeInputRef?: RefObject<HTMLInputElement>;
	private readonly passwordInputRef?: RefObject<HTMLInputElement>;

	public constructor(
		dispatch: ReduxDispatch,
		hasPremium: boolean,
		codeInputRef?: RefObject<HTMLInputElement>,
		passwordInputRef?: RefObject<HTMLInputElement>,
	) {
		this.dispatch = dispatch;
		this.hasPremium = hasPremium;
		this.codeInputRef = codeInputRef;
		this.passwordInputRef = passwordInputRef;
	}

	async #fileNotExists(text = t("thisFileNotExist")): Promise<void> {
		this.dispatch(increaseInvalidCodeAttempts(1));
		this.dispatch(setCode(""));
		await showDialog(this.dispatch, text);
		this.codeInputRef?.current?.focus();
	}

	public static isCodeInvalid(code: string): boolean {
		const codeNumber = parseInt(code);
		return isNaN(codeNumber) || codeNumber < 1000 || codeNumber > 99999999;
	}

	public async receiveFile(
		code: string,
		password?: string,
		extraAction = "previewCost",
	): Promise<void> {
		if (Receiver.isCodeInvalid(code)) {
			void this.#fileNotExists();
			return;
		}
		const action = "receive" + (password ? "WithPassword" : "");
		const captchaResponse = await showCaptcha(this.dispatch, action);
		this.dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.receiveFile(
				code,
				password,
				captchaResponse,
				extraAction,
			);
			this.dispatch(setIsLoadingScreenShown(false));
			const responseIsArray = Array.isArray(data);
			if (!responseIsArray && data.error) {
				switch (data.error) {
					case "appRequired": {
						void requireApp(
							this.dispatch,
							data.alert || data.error,
						);
						break;
					}
					case "captchaRequired": {
						if (!globals.captchaRequiredFor.has(action)) {
							globals.captchaRequiredFor.add(action);
							void this.receiveFile(code, password, extraAction);
						} else {
							void showDialog(
								this.dispatch,
								data.alert || data.error,
							);
						}
						break;
					}
					case "loginRequired": {
						if (globals.login.username) {
							void showDialog(
								this.dispatch,
								t("noPermissionToDownloadThisFile"),
							);
						} else {
							await showDialog(
								this.dispatch,
								t("loginRequiredForDownloadingThisFile"),
								{
									showCancel: true,
								},
							);
							logIn();
						}
						break;
					}
					case "notExist": {
						void this.#fileNotExists(data.alert);
						break;
					}
					case "passwordIncorrect": {
						this.dispatch(setPassword(""));
						await showDialog(this.dispatch, t("incorrectPassword"));
						this.passwordInputRef?.current?.focus();
						break;
					}
					case "passwordRequired": {
						this.dispatch(setReceivePage(ReceivePage.PASSWORD));
						break;
					}
					case "requireConfirm": {
						await showDialog(
							this.dispatch,
							data.alert || data.error,
							{ showCancel: true },
						);
						void this.receiveFile(
							code,
							password,
							data.nextAction || "",
						);
						break;
					}
					default: {
						void showResponseDialog(
							this.dispatch,
							data.alert || data.error,
							data.link,
						);
						break;
					}
				}
			} else if (!responseIsArray && data.text) {
				this.dispatch(
					setCurrentFile({
						code: code,
						region: data.region,
						text: data.text,
					}),
				);
				this.dispatch(setReceivePage(ReceivePage.TEXT_RECEIVED));
			} else if (responseIsArray) {
				if (data.length === 1 && data[0].isOwner) {
					void new Downloader(
						this.dispatch,
						data,
						this.hasPremium,
					).downloadFiles();
				} else {
					this.dispatch(
						setCurrentFile({
							code: code,
							region: data[0].region,
						}),
					);
					this.dispatch(setReceivedFiles(data));
					this.dispatch(setReceivePage(ReceivePage.FILE_LIST));
				}
			}
		} catch (error) {
			this.dispatch(setIsLoadingScreenShown(false));
			api.handleApiError(this.dispatch, error);
		}
	}
}

export default Receiver;
