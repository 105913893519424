import {
	setCurrentFile,
	setDialog,
	setIsLoadingScreenShown,
	setToast,
	setWifiTransferPage,
} from "@/redux/reducers/app";
import { ReduxDispatch, WifiTransferPage } from "@/types";
import { showDialog } from "@/utils";
import ElectronRemote from "@electron/remote";
import Electron from "electron";
import { Progress } from "electron-dl";
import fs from "fs/promises";
import { t } from "i18next";
import path from "path";
import { MouseEvent } from "react";

declare const electron: typeof Electron;
export declare const electronRemote: typeof ElectronRemote;
declare const webUtils: typeof Electron.webUtils;

export const isElectron =
	typeof require === "function" && !navigator.userAgent.includes("Cypress");
export let currentWindow: Electron.BrowserWindow;

function addListener<T>(eventName: string, callback: (args: T) => void): void {
	electron.ipcRenderer.removeAllListeners(eventName);
	electron.ipcRenderer.on(eventName, (_event, args) => {
		callback(args as T);
	});
}

/**
 * @returns false if the server has never been started, true if the server was
 * successfully closed
 */
export function closeWifiTransfer(): boolean {
	return electron.ipcRenderer.sendSync("close-wifi-transfer") as boolean;
}

export function createLivePhoto(
	dispatch: ReduxDispatch,
	videoFilename: string,
): Promise<void> {
	return new Promise<void>((resolve) => {
		addListener<string>("error", (message) => {
			void showDialog(dispatch, message, {
				title: t("error"),
			});
		});
		addListener("live-photo-completed", resolve);
		electron.ipcRenderer.send("create-live-photo", {
			downloadPath: getDownloadPath(),
			videoFilename: videoFilename,
		});
	});
}

export async function downloadFile(
	url: string,
	options: {
		onCancel: () => void;
		onProgress: (progress: Progress) => void;
		onStarted: () => void;
		openFolderWhenDone: boolean;
	},
): Promise<void> {
	return new Promise<void>((resolve) => {
		addListener("download-cancel", () => {
			options.onCancel();
		});
		addListener("download-completed", () => {
			resolve();
		});
		addListener<Progress>("download-progress", (progress) => {
			options.onProgress(progress);
		});
		addListener("download-started", () => {
			options.onStarted();
		});
		electron.ipcRenderer.send("download-file", {
			downloadPath: getDownloadPath(),
			openFolderWhenDone: options.openFolderWhenDone,
			url: url,
		});
	});
}

export function getDownloadPath(): string {
	if (!isElectron) {
		return "";
	}
	return (
		localStorage.getItem("DownloadLocation") ||
		electronRemote.app.getPath("desktop")
	);
}

export function getFilePath(filename: string): string {
	return path.join(getDownloadPath(), filename);
}

function getClientIps(): string[] {
	return electron.ipcRenderer.sendSync("get-client-ips") as string[];
}

export function getLocalIp(): string {
	if (!isElectron) {
		return "";
	}
	return electron.ipcRenderer.sendSync("get-local-ip") as string;
}

export function getPathForFile(file: File): string {
	return webUtils.getPathForFile(file);
}

export function getWifiName(): Promise<string> {
	return new Promise<string>((resolve) => {
		if (!isElectron) {
			resolve("");
			return;
		}
		addListener<string>("get-wifi-name-result", resolve);
		electron.ipcRenderer.send("get-wifi-name");
	});
}

export function hasWifiTransferClients(dispatch: ReduxDispatch): boolean {
	if (getClientIps().length === 0) {
		void showDialog(dispatch, t("noDevicesJoinedYourWifiTransferYet"));
		return false;
	}
	return true;
}

export function init(): void {
	if (!isElectron) {
		return;
	}
	currentWindow = electronRemote.getCurrentWindow();
}

export function isClipboardSyncEnabled(): boolean {
	if (!isElectron) {
		return false;
	}
	return electron.ipcRenderer.sendSync(
		"is-clipboard-sync-enabled",
	) as boolean;
}

export function isDownloadPathDesktop(): boolean {
	if (!isElectron) {
		return false;
	}
	return getDownloadPath() === electronRemote.app.getPath("desktop");
}

export function isMicaSupported(): boolean {
	if (!isElectron) {
		return false;
	}
	return electron.ipcRenderer.sendSync("is-mica-supported") as boolean;
}

export function isWifiTransferClientApproved(dispatch: ReduxDispatch): boolean {
	const isApproved = electron.ipcRenderer.sendSync(
		"is-wifi-transfer-client-approved",
	) as boolean;
	if (!isApproved) {
		void showDialog(dispatch, t("approveThisDeviceToJoinOnAnotherDevice"));
		return false;
	}
	return true;
}

export function pasteFromClipboard(event: MouseEvent<HTMLInputElement>): void {
	if (!isElectron) {
		return;
	}
	event.preventDefault();
	event.currentTarget.value += electron.clipboard.readText();
}

export function receiveFilesLocally(): void {
	electron.ipcRenderer.send("receive-files-locally");
}

export function requestWifiTransferServer(options: {
	host: string;
	method: string;
	path: string;
	timeout?: number;
	verifyCert?: boolean;
}): Promise<string | null> {
	return new Promise((resolve, reject) => {
		addListener<string>("request-wifi-transfer-server-result", (data) => {
			resolve(data);
		});
		addListener<string>("throw-error", (errorMessage) => {
			reject(new Error(errorMessage));
		});
		electron.ipcRenderer.send("request-wifi-transfer-server", options);
	});
}

export function scanNetwork(): Promise<string[]> {
	return new Promise<string[]>((resolve) => {
		if (!isElectron) {
			resolve([]);
			return;
		}
		addListener<string[]>("scan-network-result", resolve);
		electron.ipcRenderer.send("scan-network");
	});
}

export function selectDirectory(): Promise<string> {
	return new Promise<string>((resolve) => {
		addListener<string>("selected-directory", resolve);
		electron.ipcRenderer.send("select-directory");
	});
}

export function sendFilesLocally(ips?: string[], filePaths?: string[]): void {
	if (!ips || ips.length === 0) {
		ips = getClientIps();
	}
	electron.ipcRenderer.send("send-files-locally", {
		filePaths: filePaths,
		ips: ips,
	});
}

export function sendTextToClient(text: string): void {
	electron.ipcRenderer.send("send-text-to-client", text);
}

export function sendTextToServer(text: string): void {
	electron.ipcRenderer.send("send-text-to-server", text);
}

export function startClipboardSync(): void {
	electron.ipcRenderer.send("start-clipboard-sync");
}

export function startSleepBlocker(): void {
	if (!isElectron) {
		return;
	}
	electron.ipcRenderer.send("start-sleep-blocker");
}

export function startWifiTransferClient(
	dispatch: ReduxDispatch,
	serverIp: string,
	closeWifiTransferPopup: () => void,
	updateIsClipboardSyncEnabled: (newValue: boolean) => void,
): void {
	addListener("join-wifi-transfer-rejected", () => {
		void showDialog(dispatch, t("requestToJoinRejected"));
		closeWifiTransferPopup();
	});

	electron.ipcRenderer.send("start-wifi-transfer-client", serverIp);

	// The event listeners for both the server and client are added in the
	// startWifiTransferServer function.
	startWifiTransferServer(dispatch, updateIsClipboardSyncEnabled, serverIp);
}

export function startWifiTransferServer(
	dispatch: ReduxDispatch,
	updateIsClipboardSyncEnabled: (newValue: boolean) => void,
	approvedIp?: string,
): void {
	addListener("close-dialog", () => {
		dispatch(setDialog({}));
	});

	addListener("download-completed", () => {
		if (isDownloadPathDesktop()) {
			void showDialog(dispatch, t("filesDownloadedToDesktop"));
		} else {
			void showDialog(
				dispatch,
				t("filesDownloadedTo", {
					directory: getDownloadPath(),
				}),
			);
		}
	});

	addListener<string>("error", (message) => {
		void showDialog(dispatch, message, {
			title: t("error"),
		});
	});

	addListener<{
		connId: string;
		ip: string;
	}>("request-join-wifi-transfer-approval", ({ connId, ip }) => {
		void showDialog(
			dispatch,
			t("confirmJoinWifiTransferApproval", {
				ip: ip,
			}),
			{
				showCancel: true,
			},
			{
				cancel: () => {
					electron.ipcRenderer.send(
						"reject-join-wifi-transfer",
						connId,
					);
				},
				ok: () => {
					electron.ipcRenderer.send(
						"approve-join-wifi-transfer",
						connId,
					);
				},
			},
		);
	});

	addListener<string[]>("send-files-locally", (ips) => {
		sendFilesLocally(ips);
	});

	addListener<boolean>("set-is-clipboard-sync-enabled", (newValue) => {
		updateIsClipboardSyncEnabled(newValue);
	});

	addListener<boolean>("set-is-loading-screen-shown", (newValue) => {
		dispatch(setIsLoadingScreenShown(newValue));
	});

	addListener<string>("show-dialog", (text) => {
		void showDialog(dispatch, t(text));
	});

	addListener<string>("show-toast", (text) => {
		dispatch(
			setToast({
				text: t(text),
			}),
		);
	});

	addListener<string>("text-received", (text) => {
		dispatch(
			setCurrentFile({
				text: text,
			}),
		);
		dispatch(setWifiTransferPage(WifiTransferPage.TEXT_RECEIVED));
	});

	electron.ipcRenderer.send("start-wifi-transfer-server", {
		approvedIp: approvedIp,
		downloadPath: getDownloadPath(),
	});
}

export function stopClipboardSync(): void {
	electron.ipcRenderer.send("stop-clipboard-sync");
}

export function stopSleepBlocker(): void {
	if (!isElectron) {
		return;
	}
	electron.ipcRenderer.send("stop-sleep-blocker");
}

export async function writeTextFile(path: string, text: string): Promise<void> {
	await fs.writeFile(path, text);
}
