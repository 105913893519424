import * as api from "@/api";
import { importPizzip } from "@/dependencies";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import {
	closePopup,
	setCode,
	setProgress,
	setProgressPercent,
	setReceivePage,
} from "@/redux/reducers/app";
import { FileInfo, ReceivePage, ReduxDispatch } from "@/types";
import { requireApp, showDialog, sleep } from "@/utils";
import { Progress } from "electron-dl";
import { t } from "i18next";
import PizZip from "pizzip";
import { v4 as uuidv4 } from "uuid";

const { MB } = globals;

class Downloader {
	private readonly dispatch: ReduxDispatch;
	private readonly filesInfo: FileInfo[];
	private readonly hasPremium: boolean;

	public constructor(
		dispatch: ReduxDispatch,
		filesInfo: FileInfo[],
		hasPremium: boolean,
	) {
		this.dispatch = dispatch;
		this.filesInfo = filesInfo;
		this.hasPremium = hasPremium;
	}

	#downloadArrayBuffer(
		url: string,
		filename: string,
		size: number,
	): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			this.dispatch(
				setProgress({
					filename: filename,
					isReturnPremiumNoticeShown:
						this.hasPremium &&
						size > globals.PREMIUM_REQUIRED_FILE_SIZE,
					percent: 0,
				}),
			);
			this.dispatch(setReceivePage(ReceivePage.PROGRESS));

			const xhr = new XMLHttpRequest();
			xhr.responseType = "arraybuffer";
			xhr.onload = (): void => {
				if (xhr.status === 200) {
					resolve(xhr.response as ArrayBuffer);
				} else {
					if (xhr.status === 404) {
						void showDialog(this.dispatch, t("fileIncomplete"));
					} else {
						api.handleApiError(
							this.dispatch,
							new Response(null, { status: xhr.status }),
						);
					}
					reject(new Error());
				}
			};
			xhr.onprogress = (event): void => {
				if (!event.lengthComputable) {
					return;
				}
				this.dispatch(setProgressPercent(event.loaded / event.total));
			};
			xhr.open("GET", url, true);
			xhr.send();
		});
	}

	public static downloadDataUrl(dataUrl: string, filename: string): void {
		const newLink = document.createElement("a");
		newLink.hidden = true;
		newLink.href = dataUrl;
		newLink.download = filename;
		document.body.appendChild(newLink);
		newLink.click();
		newLink.remove();
	}

	public async downloadFiles(): Promise<void> {
		if (this.filesInfo.length === 1 && !globals.isApp) {
			const downloadLink = this.filesInfo[0].download?.[0];
			if (!downloadLink) {
				void showDialog(this.dispatch, t("noDownloadAddress"), {
					title: t("error"),
				});
				this.#finishDownload();
				return;
			}
			window.location.href = downloadLink;
			this.#finishDownload();
			return;
		}
		const totalSize = this.filesInfo.reduce(
			(total, fileInfo) => total + (fileInfo.size || 0),
			0,
		);
		const shouldZip =
			this.filesInfo.length > 1 && !globals.isApp && totalSize < 100 * MB;
		const writtenFiles = new Set<string>();
		let dirHandle: FileSystemDirectoryHandle | null = null;
		let zip: PizZip | null = null;
		if (!globals.isApp && !shouldZip) {
			if (
				globals.isMobile ||
				typeof showDirectoryPicker !== "function" ||
				navigator.userAgent.includes("OPR/")
			) {
				void requireApp(this.dispatch, t("batchDownloadRequiresApp"));
				return;
			}
			await showDialog(
				this.dispatch,
				t("thisFeatureRequiresChromeOrApp"),
			);
			await sleep(250);
			await showDialog(this.dispatch, t("selectFileSaveLocation"));
			dirHandle = await showDirectoryPicker();
		}
		for (const fileInfo of this.filesInfo) {
			const filename = ((): string => {
				let result = "";
				if (globals.login.username === "admin") {
					if (fileInfo.code) {
						result += fileInfo.code;
					} else if (fileInfo.download?.[0]) {
						result += fileInfo.download[0].split("/")[3];
					}
					if (result) {
						result += "-";
					}
				}
				result += decodeURIComponent(fileInfo.name || "");
				if (writtenFiles.has(result)) {
					const extension = result.includes(".")
						? "." + result.split(".").pop()
						: "";
					result = uuidv4() + extension;
				}
				writtenFiles.add(result);
				return result;
			})();

			const filePath = this.#getFilePath(filename);
			let writable: FileSystemWritableFileStream | null = null;
			if (!globals.isApp && dirHandle) {
				const fileHandle = await dirHandle.getFileHandle(filename, {
					create: true,
				});
				writable = await fileHandle.createWritable();
			}
			if (fileInfo.text) {
				// for admin batch download
				try {
					if (desktopApp.isElectron) {
						await desktopApp.writeTextFile(filePath, fileInfo.text);
					} else if (writable) {
						await writable.write(
							new Blob([fileInfo.text], {
								type: "text/plain",
							}),
						);
						await writable.close();
					}
				} catch (error) {
					if (error instanceof Error) {
						void showDialog(this.dispatch, error.message);
					}
				}
				continue;
			}
			const downloadLink = fileInfo.download?.[0];
			if (!downloadLink) {
				void showDialog(this.dispatch, t("noDownloadAddress"), {
					title: t("error"),
				});
				this.#finishDownload();
				return;
			}
			const size = fileInfo.size || 0;
			if (desktopApp.isElectron) {
				await desktopApp.downloadFile(downloadLink, {
					onCancel: (): void => {
						this.#finishDownload();
					},
					onProgress: (progress: Progress): void => {
						this.dispatch(setProgressPercent(progress.percent));
					},
					onStarted: (): void => {
						this.dispatch(
							setProgress({
								filename: filename,
								isReturnPremiumNoticeShown:
									this.hasPremium &&
									size > globals.PREMIUM_REQUIRED_FILE_SIZE,
								percent: 0,
							}),
						);
						this.dispatch(setReceivePage(ReceivePage.PROGRESS));
					},
					openFolderWhenDone: this.filesInfo.length === 1,
				});
			} else if (shouldZip) {
				try {
					if (!zip) {
						const PizZip = await importPizzip();
						zip = new PizZip();
					}
					const arrayBuffer = await this.#downloadArrayBuffer(
						downloadLink,
						filename,
						size,
					);
					zip.file(filename, arrayBuffer);
				} catch (error) {
					if (error instanceof Error) {
						void showDialog(this.dispatch, error.message);
					}
					this.#finishDownload();
					return;
				}
			} else {
				try {
					const arrayBuffer = await this.#downloadArrayBuffer(
						downloadLink,
						filename,
						size,
					);
					if (writable) {
						await writable.write(arrayBuffer);
						await writable.close();
					}
				} catch (error) {
					if (error instanceof Error) {
						void showDialog(this.dispatch, error.message);
					}
					this.#finishDownload();
					return;
				}
			}
		}
		if (desktopApp.isElectron) {
			if (globals.isWindows) {
				for (const fileInfo of this.filesInfo) {
					if (!fileInfo.name?.endsWith(".pvt.mov")) {
						continue;
					}
					await desktopApp.createLivePhoto(
						this.dispatch,
						fileInfo.name,
					);
				}
			}
			if (desktopApp.isDownloadPathDesktop()) {
				void showDialog(this.dispatch, t("filesDownloadedToDesktop"));
			} else {
				void showDialog(
					this.dispatch,
					t("filesDownloadedTo", {
						directory: desktopApp.getDownloadPath(),
					}),
				);
			}
		} else if (shouldZip) {
			if (zip) {
				const blob = zip.generate({
					type: "blob",
				});
				const dataUrl = URL.createObjectURL(blob);
				Downloader.downloadDataUrl(
					dataUrl,
					`${globals.APP_NAME}-${Date.now()}.zip`,
				);
			}
		} else {
			void showDialog(this.dispatch, t("filesDownloaded"));
		}
		this.#finishDownload();
	}

	public static downloadText(text: string, filename: string): void {
		this.downloadDataUrl(
			"data:text/plain;charset=utf-8," + encodeURIComponent(text),
			filename,
		);
	}

	#finishDownload(): void {
		this.dispatch(closePopup("receive"));
		this.dispatch(setCode(""));
	}

	#getFilePath(filename: string): string {
		if (desktopApp.isElectron) {
			return desktopApp.getFilePath(filename);
		} else {
			return filename;
		}
	}
}

export default Downloader;
