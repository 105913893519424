import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export interface DialogInfo extends NotificationInfo {
	defaultText?: string;
	extraButtons?: string[];
	html?: string;
	inputType?: string;
	isPrompt?: boolean;
	okText?: string;
	placeholder?: string;
	showCancel?: boolean;
}

export type ReduxDispatch = (action: {
	type: string;
	payload?: unknown;
}) => void;

export interface DynamicInfo {
	alert?: string;
	appDlLink?: string;
	blockReason?: string;
	downloadLink?: string;
	executable?: string;
	gray?: boolean;
	latestVersion?: string;
	link?: string;
	links?: {
		link: string;
		text: string;
	};
	loaded?: boolean;
	login?: string;
	posters?: PosterInfo[];
	publicIp?: string;
	redirectTo?: string;
	region?: string;
	selectedServer?: string;
	servers?: ServerInfo;
	sw?: boolean;
}

export interface FileInfo {
	code?: string;
	download?: string[];
	isOwner?: boolean;
	name?: string;
	region?: string;
	size?: number;
	text?: string;
	type?: string;
}

export interface LinkInfo {
	link: string;
	text: string;
}

export interface LoginInfo {
	birthday?: string;
	email?: string;
	name?: string;
	phone?: string;
	token?: string;
	username?: string;
}

export interface MenuItemInfo {
	hasPopup: boolean;
	icon: IconDefinition;
	label: string;
	onClick: () => void | Promise<void>;
	when: boolean;
}

export interface MyFileInfo {
	code: number;
	downloads: number;
	hasUnlimitedDownloads?: boolean;
	key: string;
	name: string;
	type?: string;
}

export interface NotificationInfo {
	text?: string;
	title?: string;
}

export interface OssCredentials {
	accessKeyId: string;
	accessKeySecret: string;
	stsToken: string;
}

export interface PosterInfo {
	alt: string;
	isArticle: boolean;
	link: string;
	src: string;
}

export interface ProfileResponse {
	phone?: string;
	realIdLevel?: RealIdLevel;
	realName?: string;
	regTime?: {
		details: string;
		time: string;
	};
	tfa?: boolean;
}

export interface ProgressInfo {
	denominator?: number;
	filename?: string;
	isReturnPremiumNoticeShown?: boolean;
	percent?: number;
}

export enum RealIdLevel {
	NO_REAL_ID,
	EMAIL_VERIFIED,
	PHONE_VERIFIED,
	REAL_ID_VERIFIED,
}

export type ReceiveFileResponse = FileInfo[] | ReceiveFileResponseJson;

interface ReceiveFileResponseJson {
	alert?: string;
	error?: string;
	link?: string;
	nextAction?: string;
	region?: string;
	text?: string;
}

export enum ReceivePage {
	MY_FILES,
	CODE,
	PASSWORD,
	FILE_LIST,
	PROGRESS,
	TEXT_RECEIVED,
	REPORT,
}

export enum SendPage {
	FILE_LIST,
	SEND_TEXT,
	AGREEMENT,
	ID_SELECTOR,
	SENDER_CODE,
	PROGRESS,
	SUCCESS,
	QR_CODE,
}

export interface SendSuccessInfo {
	code?: number;
	key?: string;
}

export interface ServerInfo {
	[key: string]: {
		host: string;
		name: string;
	};
}

export interface SettingsInfo {
	ip?: string;
	loginRequired?: boolean;
}

export enum TextAreaPage {
	SEND_TEXT,
	SEND_TEXT_LOCALLY,
	TEXT_RECEIVED,
	TEXT_RECEIVED_LOCALLY,
	REPORT,
}

export interface ToastInfo {
	text?: string;
	timeout?: number;
}

export enum WifiTransferPage {
	MODE,
	DEVICE_LIST,
	DASHBOARD,
	SEND_TEXT,
	TEXT_RECEIVED,
}

export interface WindowExt extends Window {
	TencentCaptcha?: typeof TencentCaptcha;
}
