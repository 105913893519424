import * as api from "@/api";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	openPopup,
	setIsLoadingScreenShown,
	setIsMenuShown,
	setPremiumData,
	setReceivePage,
	setSendPage,
	setWifiTransferPage,
} from "@/redux/reducers/app";
import styles from "@/styles/MainMenu.module.css";
import { MenuItemInfo, ReceivePage, SendPage, WifiTransferPage } from "@/types";
import {
	getHelp,
	handleKeyboardClick,
	logIn,
	openAccount,
	requireApp,
	showDialog,
	toGB,
} from "@/utils";
import {
	faCircleArrowUp,
	faCircleInfo,
	faCircleQuestion,
	faCircleUser,
	faDownload,
	faEnvelope,
	faFolder,
	faGear,
	faRightFromBracket,
	faSquareArrowUpRight,
	faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogPanel } from "@headlessui/react";
import classNames from "classnames";
import { t } from "i18next";
import { Fragment, useState } from "react";

interface MenuProps {
	isOpen: boolean;
}

function Menu({ isOpen }: MenuProps): JSX.Element {
	const dispatch = useAppDispatch();
	const links = useAppSelector((state) => state.app.links);
	const login = useAppSelector((state) => state.app.login);
	const premiumData = useAppSelector((state) => state.app.premiumData);

	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closeMenu = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(setIsMenuShown(false));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const downloadApp = async (): Promise<void> => {
		dispatch(setIsMenuShown(false));
		const osName = ((): string | null => {
			if (globals.isAndroid) {
				return "Android";
			} else if (globals.isIos) {
				return "iOS";
			} else if (globals.isMac) {
				return "macOS";
			} else if (globals.isWindows) {
				return "Windows";
			} else if (globals.isLinux) {
				return "Linux";
			}
			return null;
		})();
		if (osName) {
			await showDialog(
				dispatch,
				t("osDetectedGoingToAppDownloadPage", {
					os: osName,
				}),
				{
					showCancel: true,
				},
			);
		}
		window.open(globals.dynamicInfo.appDlLink);
	};

	const handleAccountInfoClick = (): void => {
		const handleVisibilityChange = (): void => {
			// when switching back from account
			if (!document.hidden) {
				api.getExpirationTime()
					.then((data) => {
						if (data.data) {
							dispatch(setPremiumData(data.data));
						}
					})
					.catch((error) => {
						api.handleApiError(dispatch, error);
					});
				removeEventListener("visibilitychange", handleVisibilityChange);
			}
		};

		addEventListener("visibilitychange", handleVisibilityChange);
		closeMenu();
		openAccount("premium");
	};

	const linkElements = links.map((link) => {
		const handleClick = (): void => {
			dispatch(setIsMenuShown(false));
			window.open(link.link);
		};

		return (
			<div
				key={link.text}
				className={styles["menu-item"]}
				role="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				<FontAwesomeIcon
					className={styles["icon"]}
					icon={faSquareArrowUpRight}
				/>
				<span className={styles["content"]}>{link.text}</span>
			</div>
		);
	});

	const menuItems: readonly MenuItemInfo[] = [
		{
			hasPopup: false,
			icon: faCircleUser,
			label: t("login"),
			onClick: (): void => {
				logIn();
			},
			when: !!globals.dynamicInfo.loaded && !globals.login.username,
		},
		{
			hasPopup: true,
			icon: faRightFromBracket,
			label: t("logOut"),
			onClick: async (): Promise<void> => {
				await showDialog(dispatch, t("confirmLogOut"), {
					showCancel: true,
				});
				dispatch(setIsLoadingScreenShown(true));
				try {
					await api.logOut();
					localStorage.clear();
					window.location.reload();
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
			},
			when: !!globals.login.username,
		},
		{
			hasPopup: !!globals.login.username,
			icon: faFolder,
			label: t("myFiles"),
			onClick: (): void => {
				if (!globals.login.username) {
					logIn();
					return;
				}
				dispatch(setReceivePage(ReceivePage.MY_FILES));
				dispatch(openPopup("receive"));
			},
			when: !!globals.dynamicInfo.loaded,
		},
		{
			hasPopup: !!globals.login.username,
			icon: faCircleArrowUp,
			label: t("getSenderCode"),
			onClick: (): void => {
				if (!globals.login.username) {
					logIn();
					return;
				}
				dispatch(openPopup("senderCode"));
			},
			when: !!globals.dynamicInfo.loaded,
		},
		{
			hasPopup: true,
			icon: faEnvelope,
			label: t("sendText"),
			onClick: (): void => {
				dispatch(setSendPage(SendPage.SEND_TEXT));
				dispatch(openPopup("send"));
			},
			when: !!globals.dynamicInfo.loaded,
		},
		{
			hasPopup: true,
			icon: faWifi,
			label: t("wifiTransfer"),
			onClick: (): void => {
				if (!globals.isApp) {
					void requireApp(dispatch);
					return;
				}
				dispatch(setWifiTransferPage(WifiTransferPage.MODE));
				dispatch(openPopup("wifiTransfer"));
			},
			when:
				globals.isApp ||
				(!globals.isMobile && !!globals.dynamicInfo.appDlLink),
		},
		{
			hasPopup: true,
			icon: faGear,
			label: t("settings"),
			onClick: (): void => {
				dispatch(openPopup("settings"));
			},
			when: true,
		},
		{
			hasPopup: false,
			icon: faCircleQuestion,
			label: t("helpAndFeedback"),
			onClick: (): void => {
				dispatch(setIsMenuShown(false));
				getHelp();
			},
			when: !globals.isTencent && !!globals.dynamicInfo.loaded,
		},
		{
			hasPopup: true,
			icon: faCircleInfo,
			label: t("about"),
			onClick: (): void => {
				dispatch(openPopup("about"));
			},
			when: true,
		},
		{
			hasPopup: true,
			icon: faDownload,
			label: t("downloadApp"),
			onClick: (): void => {
				void downloadApp();
			},
			when: !globals.isApp && !!globals.dynamicInfo.appDlLink,
		},
	];

	const menuItemElements = menuItems.map((item): JSX.Element => {
		if (!item.when) {
			return <Fragment key={item.label} />;
		}

		const handleClick = (): void => {
			dispatch(setIsMenuShown(false));
			void item.onClick();
		};

		return (
			<div
				aria-haspopup={item.hasPopup ? "dialog" : undefined}
				key={item.label}
				className={styles["menu-item"]}
				role="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				<FontAwesomeIcon
					className={styles["icon"]}
					icon={item.icon}
				/>
				<span className={styles["content"]}>{item.label}</span>
			</div>
		);
	});

	return (
		<Dialog
			onClose={closeMenu}
			open={isOpen}
		>
			<div
				className={classNames({
					"fade-out": isClosing,
					"overlay": true,
				})}
			>
				<DialogPanel className={styles["menu"]}>
					{login.username && (
						<div
							className={classNames(
								styles["menu-item"],
								styles["multiline"],
							)}
							role="button"
							tabIndex={0}
							onClick={handleAccountInfoClick}
							onKeyDown={handleKeyboardClick(
								handleAccountInfoClick,
							)}
						>
							<FontAwesomeIcon
								className={styles["icon"]}
								icon={faCircleUser}
							/>
							<div className={styles["content"]}>
								<div>
									{login.name || login.email || login.phone}
								</div>
								<p>
									{premiumData > 0
										? t("premiumRemaining", {
												data: toGB(premiumData) + " GB",
											})
										: t("clickHereActivatePremium")}
								</p>
							</div>
						</div>
					)}
					{menuItemElements}
					{links.length > 0 && (
						<div className={styles["menu-line"]}></div>
					)}
					{linkElements}
				</DialogPanel>
			</div>
		</Dialog>
	);
}

export default Menu;
