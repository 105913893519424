import globals from "@/globals";
import { useAppSelector } from "@/redux/hooks";
import styles from "@/styles/Progress.module.css";
import { getHelp } from "@/utils";
import classNames from "classnames";
import { t } from "i18next";
import { useEffect, useState } from "react";

interface ProgressProps {
	title: string;
}

function Progress({ title }: ProgressProps): JSX.Element {
	const progress = useAppSelector((state) => state.app.progress);

	const providedPercent = progress.percent || 0;

	const [percentOverride, setPercentOverride] =
		useState<number>(providedPercent);

	const percent = Math.round(
		(progress.denominator ? percentOverride : providedPercent) * 100,
	);

	useEffect(() => {
		// needs denominator to predict progress
		if (!progress.denominator || progress.percent === undefined) {
			return;
		}
		const intervalId = window.setInterval(() => {
			if (!progress.denominator || progress.percent === undefined) {
				return;
			}
			if (progress.percent > percentOverride) {
				setPercentOverride(progress.percent);
				return;
			}
			const nextPercent = progress.percent + 1 / progress.denominator;
			const increasedPercent = percentOverride + 0.01;
			if (increasedPercent >= nextPercent || increasedPercent >= 0.99) {
				return;
			}
			setPercentOverride(increasedPercent);
		}, globals.ANIMATION_WAIT_TIME);
		return (): void => {
			window.clearInterval(intervalId);
		};
	}, [percentOverride, progress.denominator, progress.percent]);

	useEffect(() => {
		document.title = `[${percent}%] ${t("airportalTitle")}`;
		return (): void => {
			document.title = t("airportalTitle");
		};
	}, [percent]);

	if (progress.percent === undefined) {
		return <></>;
	}
	return (
		<>
			<div
				className={classNames(
					"universal-container",
					styles["container"],
				)}
			>
				<div
					className={classNames("popup-title", styles["popup-title"])}
				>
					{percent >= 99 ? t("almostThere") : title}
				</div>
				<div className={styles["description"]}>{progress.filename}</div>
				<div className={styles["progress-label"]}>
					{percent}
					<span>%</span>
				</div>
				<div
					className={classNames(
						styles["progress-bar"],
						styles["progress-bar-background"],
					)}
				>
					<div
						className={classNames(
							styles["progress-bar"],
							styles["progress-bar-foreground"],
						)}
						role="progressbar"
						style={{ width: percent + "%" }}
						aria-valuenow={percent}
						aria-valuemin={0}
						aria-valuemax={100}
					></div>
				</div>
			</div>
			{progress.isReturnPremiumNoticeShown && (
				<button
					className={styles["description"]}
					onClick={getHelp}
					type="button"
				>
					{t("ifTransferFailsClickHereToReturnPremiumData")}
				</button>
			)}
		</>
	);
}

export default Progress;
