import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setCode } from "@/redux/reducers/app";
import styles from "@/styles/ReceiveCodeInput.module.css";
import classNames from "classnames";
import { t } from "i18next";
import { ChangeEvent, FormEvent, RefObject, useId } from "react";

interface ReceiveCodeInputProps {
	inputRef: RefObject<HTMLInputElement>;
	receiveFile: (code: string) => void;
}

function ReceiveCodeInput({
	inputRef,
	receiveFile,
}: ReceiveCodeInputProps): JSX.Element {
	const dispatch = useAppDispatch();

	const code = useAppSelector((state) => state.app.code);
	const invalidAttempts = useAppSelector(
		(state) => state.app.invalidAttempts,
	);

	const id = useId();

	const handleCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
		dispatch(setCode(event.target.value));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		if (code) {
			if (invalidAttempts.code > 2) {
				globals.captchaRequiredFor.add("receive");
			}
			receiveFile(code);
		} else {
			inputRef.current?.focus();
		}
	};

	return (
		<form
			className="universal-container"
			onSubmit={handleSubmit}
		>
			<div className="popup-title">
				<label htmlFor={id}>{t("enterCode")}</label>
				<div className="tip">{t("youWillGetCodeAfterSendingFile")}</div>
			</div>
			<div>
				<input
					className={classNames({
						input: true,
						[styles["receive-input"]]: true,
						[styles["compact"]]: code.length > 6,
					})}
					data-autofocus
					data-testid="code-input"
					id={id}
					ref={inputRef}
					type="tel"
					autoComplete="off"
					inputMode="numeric"
					value={code}
					onChange={handleCodeChange}
				/>
			</div>
			<button
				className="popup-main-button"
				type="submit"
			>
				{t("ok")}
			</button>
		</form>
	);
}

export default ReceiveCodeInput;
