import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setDialog } from "@/redux/reducers/app";
import styles from "@/styles/Dialog.module.css";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { t } from "i18next";
import {
	ChangeEvent,
	KeyboardEvent,
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from "react";

function MessageDialog(): JSX.Element {
	const dispatch = useAppDispatch();
	const dialog = useAppSelector((state) => state.app.dialog);

	const [inputValue, setInputValue] = useState<string>(
		dialog.defaultText || "",
	);
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const closeDialog = (): void => {
		globals.dialogCallbacks = {};
		setIsClosing(true);
		window.setTimeout(() => {
			setInputValue("");
			dispatch(setDialog({}));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleCancelClick = (event: MouseEvent<HTMLButtonElement>): void => {
		globals.dialogCallbacks.cancel?.(event);
		closeDialog();
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleInputKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
	): void => {
		if (event.key === "Enter") {
			handleOkClick();
		}
	};

	const handleOkClick = (event?: MouseEvent<HTMLButtonElement>): void => {
		globals.dialogCallbacks.ok?.(event, inputValue);
		closeDialog();
	};

	const extraButtonElements = dialog.extraButtons?.map((item) => {
		const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
			globals.dialogCallbacks[item]?.(event);
			closeDialog();
		};

		return (
			<button
				className={classNames(
					styles["dialog-button"],
					styles["negative"],
				)}
				key={item}
				type="button"
				onClick={handleClick}
			>
				{t(item)}
			</button>
		);
	});

	useEffect(() => {
		if (dialog.isPrompt) {
			window.setTimeout(() => {
				inputRef.current?.focus();
			}, globals.ANIMATION_WAIT_TIME);
		}
	}, [dialog]);

	return (
		<Dialog
			onClose={closeDialog}
			open={!!dialog.html || !!dialog.text}
		>
			<div
				className={classNames({
					"overlay": true,
					"zoom-in": !isClosing,
					"zoom-out": isClosing,
				})}
			>
				<div className={styles["dialog"]}>
					{!dialog.isPrompt && (
						<div className={styles["dialog-title"]}>
							{dialog.title ||
								(dialog.showCancel ? t("confirm") : t("alert"))}
						</div>
					)}
					<div className={styles["dialog-content"]}>
						{dialog.html && (
							<span
								dangerouslySetInnerHTML={{
									__html: dialog.html,
								}}
							></span>
						)}
						{dialog.text}
					</div>
					{dialog.isPrompt && (
						<input
							ref={inputRef}
							className={classNames(
								"input",
								styles["dialog-input"],
							)}
							placeholder={dialog.placeholder}
							type={dialog.inputType || "text"}
							value={inputValue}
							onChange={handleInputChange}
							onContextMenu={desktopApp.pasteFromClipboard}
							onKeyDown={handleInputKeyDown}
						/>
					)}
					<div className={styles["dialog-buttons"]}>
						{extraButtonElements}
						{dialog.showCancel && (
							<button
								className={classNames(
									styles["dialog-button"],
									styles["negative"],
								)}
								type="button"
								onClick={handleCancelClick}
							>
								{t("cancel")}
							</button>
						)}
						<button
							className={classNames(
								styles["dialog-button"],
								styles["positive"],
							)}
							data-autofocus
							type="button"
							onClick={handleOkClick}
						>
							{dialog.okText || t("ok")}
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default MessageDialog;
