import styles from "@/styles/LoadingScreen.module.css";
import classNames from "classnames";

function LoadingScreen(): JSX.Element {
	return (
		<div className={classNames("overlay", styles["blur"])}>
			<div
				className={styles["loader"]}
				data-testid="loading"
			></div>
		</div>
	);
}

export default LoadingScreen;
